<app-header>
    <a href="#services" class="menu-item">Services</a>
    <a href="#process" class="menu-item">Working process</a>
</app-header>
<div class="center-align">
    <div class="landing-page">
        <span align="center">
            Unlocking Confidentiality <br> Your NDA Chat Bot Companion
        </span>
        <span class="sub-title">
            Discover NDA Chat Bot: Your confidential conversation companion. Protect sensitive information effortlessly,
            stay compliant, and focus on what matters most.
        </span>
    </div>
    <div class="button" [routerLink]="['/generate-nda']" routerLinkActive="active">
        Generate now
    </div>
    <div class="working-process" id="services">
        <div class="section">
            <div class="section-title">
                <span>Services</span>
            </div>
            <span class="section-info">Elevate your confidentiality with our NDA Chat Bot service—seamless, secure, and
                compliant
                messaging, ensuring your peace of mind in every conversation. Say goodbye to paperwork and hello to
                effortless privacy management.</span>
        </div>
    </div>
    <div class="card-holder">
        <div class="cards" *ngFor="let card of cardList; index as idx">
            <img class="card-icons" src='../assets/card-{{idx+1}}.svg' />
            <span align="center" [innerHtml]="card"></span>
            <!-- <span style="padding-right: 50px;">{{ cardContent[idx] }}</span> -->
        </div>
    </div>
    <div class="working-process" id="process">
        <div class="section">
            <div class="section-title">
                <span>Our Working Process</span>
            </div>
            <span class="section-info">Step-by-Step Guide to Achieving Your Goals</span>
        </div>
    </div>
    <div style="width: 85%;">
        <div *ngFor="let acc of accordianTitle; index as idx">
            <div [ngClass]="toggle[idx] ? 'accordian expanded' : 'accordian hidden'">
                <div class="accordian-title">
                    <span>
                        <span style="font-size: 50px; font-weight: 400;">0{{idx + 1}}</span> &nbsp; &nbsp;
                        <span style="font-weight: 400;">{{acc}}</span>
                    </span>
                    <img *ngIf="!toggle[idx]" src="../../../assets/plus.svg" (click)="toggleAccordian(idx)">
                    <img *ngIf="toggle[idx]" src="../../../assets/minus.svg" (click)="toggleAccordian(idx)">
                </div>
                <span [ngStyle]="{'display': toggle[idx] ? 'contents' : 'none'}" style="font-size: 18px;">
                    <hr>
                    {{accordianContent[idx]}}
                </span>
            </div>
        </div>
    </div>
</div>
<div class="footer">
    © 2024 NDA. All Rights Reserved.
</div>