import { Routes } from '@angular/router';
import { LandingPageComponent } from './modules/landing-page/landing-page.component';

export const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent
  },
  {
    path: 'generate-nda',
    loadComponent: () => import('./modules/chat-widget-page/chat-widget-page.component').then(m => m.ChatWidgetPageComponent)
  }
  /* {
    path: 'agent',
    loadChildren: () => import('./modules/agent/agent.module').then(m=>m.AgentModule)
  },
  {
    path: '**',
    redirectTo: 'auth'
  }  */
];


