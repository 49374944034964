import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';

import { Observable, EMPTY, throwError, of } from 'rxjs';
import { catchError, startWith } from 'rxjs/operators';
import { ApiService } from '../services/api.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private apiService: ApiService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        /* if (error.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          
        } */

        if (request.url.includes('storeResponses') && request.body?.update) {
          this.apiService.setFailedStatus({ status: 'failed', serialNumber: request.body.serialNumber });
          return EMPTY;
        }
        if (error.error.status === 405) {
          this.apiService.setRefreshPopup(true);
          return EMPTY;
        }
        if (error.error.status === 422) return EMPTY;
        this.apiService.setErrorPopup(true);
        return EMPTY;
      })
    );
  }
}